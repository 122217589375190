import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, forkJoin, Observable, Subscription } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import firebase from "firebase/app";
import firestore = firebase.firestore;
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AvailableSeats, UpdateTickets, responseGetVmtTimingModel } from '../core/models';
import { dateStringToDate } from 'tixygo-lib';
import { AngularFireAuth } from '@angular/fire/auth';
import { responseReleaseVmtSeatsModel } from '../core/models/DeleteVmtSeats.model';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public paymentProcessStep = 1;  // Variable para pasos en modal de pago

  private url = 'events';
  private urlPlaces = 'places';
  private urlZones = 'zones';
  private urlReservations = 'reservations';
  private urlTransactions = 'transactions_openpay';
  private setCountries = 'countries';
  private transactionSubscription: Subscription;
  pendingReservation;

  public XSignatureAndToken = {
    token: '',
    xSignature: ''
  }
  eventId = "";

  private dataSubject = new BehaviorSubject<any>(null);
  data$: Observable<any> = this.dataSubject.asObservable();
  array = [];
  date;
  endDate;

  categorySelectedData = {
    data: null
  };

  updateData(newData: any) {
    this.dataSubject.next(newData);
  }
  constructor(
    private firestore: AngularFirestore,
    private http: HttpClient,
    private afAuth: AngularFireAuth
  ) { }

  /**
   * Observable de los usaurios de app móvil.
   */
  getEvents(): Observable<any[]> {
    //return this.firestore.collection(this.url).valueChanges();
    return this.firestore.collection(this.url, ref => ref.where('post', '==', true)).valueChanges();
  }
  

  getEventsByCategory(categoryId: string): Observable<any[]> {
    return this.firestore.collection(this.url, ref => ref.where('category', '==', categoryId)).valueChanges();
  }
  getDocumentsWithin24Hours() {
    // Calculate timestamps for the start and end of the 24-hour period
    console.log('new date', new Date());
    const startTimestamp = new Date();
    startTimestamp.setHours(0, 0, 0, 0);
    // const endTimestamp = new Date();
    // endTimestamp.setHours(23, 59, 59, 999);

    const startTimestampFirestore = firebase.firestore.Timestamp.fromDate(startTimestamp);

    return this.firestore.collection(this.url, ref =>
      ref.where('queryDates', 'array-contains', startTimestampFirestore) // Check if the array contains a timestamp within the start time
        .orderBy('startTime') // Optional: Order the results
    ).valueChanges();
  }

  /**
   * Función para guardar un asiento en base de datos
   */
  saveSeatSelection(index: number, user: any): Promise<void> {
    return this.firestore.collection('events').doc('noid')
      .collection('seatmap').doc(`${index}`).update({
        userData: {
          name: user.first_name + ' ' + user.last_name,
          email: user.email,
          phone: user.phone,
          timestamp: new Date()
        }
      });
  }


  /**
   * Observable de para obtener el documento de 1 evento
   */
  getEvent(eventId: string): Observable<any> {
    return this.firestore.collection(this.url).doc(eventId).valueChanges();
  }

  getEventOnce(eventId: string): Observable<any> {
    return this.firestore.collection(this.url).doc(eventId).get();
  }

  /**
   *  Observable para obtener el mapa de asientos (place/location) asignado a un evento
   */
  getEventLocation(eventId: string, placeId: string): Observable<any> {
    return this.firestore.collection(this.url).doc(eventId)
      .collection(this.urlPlaces).doc(placeId).valueChanges();
  }
  getEventLocationOnce(eventId: string, placeId: string): Observable<any> {
    return this.firestore.collection(this.url).doc(eventId)
      .collection(this.urlPlaces).doc(placeId).get();
  }
  getEventSeatmapZones(eventId: string, placeId: string): Observable<any[]> {
    return this.firestore.collection(this.url).doc(eventId)
      .collection(this.urlPlaces).doc(placeId).collection(this.urlZones, ref =>
        ref.orderBy('number_of_seats', 'desc')).valueChanges();
  }

  getEventSeatmapZonesOnce(eventId: string, placeId: string): Promise<any[]> {
    return this.firestore.collection(this.url)
      .doc(eventId)
      .collection(this.urlPlaces)
      .doc(placeId)
      .collection(this.urlZones, (ref) =>
        ref.orderBy('number_of_seats', 'desc')
      )
      .get()
      .toPromise()
      .then((querySnapshot) => {
        const zones = [];
        querySnapshot.forEach((doc) => {
          zones.push(doc.data());
        });
        return zones;
      });
  }

  saveEventSeatMapZones(eventId: string, locationId: string, zones: any[], reservation: any): Promise<void> {
    const batch = this.firestore.firestore.batch();
    zones.forEach(zone => {
      batch.update(this.firestore.collection(this.url).doc(eventId)
        .collection(this.urlPlaces).doc(locationId)
        .collection(this.urlZones).doc(zone.id).ref, {
        blueprint: zone.blueprint ? zone.blueprint : '#',
        coordinates: zone.coordinates,
        created_at: zone.created_at,
        id: zone.id,
        name: zone.name,
        number_of_seats: zone.number_of_seats,
        seats: zone.seats,
        updated_at: zone.updated_at
      });

      // si se pasó la reservación entonces crear un batch para incremente "purchased_tickets" de cada sección
      if (reservation && !zone.seatingSelection) {
        var count = 0;
        reservation.selected_seats.forEach(s => {
          if (s.section_id === zone.id) {
            count++;
          }
        });
        if (count > 0) {
          batch.update(this.firestore.collection(this.url).doc(eventId)
            .collection(this.urlPlaces).doc(locationId)
            .collection(this.urlZones).doc(zone.id).ref, {
            purchased_tickets: firestore.FieldValue.increment(count)
          });
        }
      }

    });
    return batch.commit();
  }

  /**
   * Guardar reservación de un usuario en base de datos
   * se guarda en colección general 'reservations'
   * y en subcolección del evento 'events/{id}/reservations
   *
   */
  saveReservation(eventId: string, reservation: any) {
    this.eventId = eventId;
    const batch = this.firestore.firestore.batch();
    const reservationsCollections = this.firestore.collection(this.url).doc(eventId).collection(this.urlReservations).doc(reservation.id).ref;
    batch.set(reservationsCollections, reservation);
    return batch.commit();
  }

  getReservation(reservationId: string): Observable<any> {
    return this.firestore.collection(this.urlReservations)
      .doc(reservationId).valueChanges();
  }

  getReservationOnce(reservationId: string): Observable<any> {
    return this.firestore.collection(this.url).doc(this.eventId).collection(this.urlReservations).doc(reservationId).get();
  }

  getReservationByReservationId(reservationId: string) {
    return this.firestore.collection(this.urlReservations).doc(reservationId).snapshotChanges();
  }

  /*
    * Consultar si está disponible el asiento para poder pagarlo.
  */
  getSeatAvailable(XSignature: string, token: string): Observable<AvailableSeats> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8',
      'X-Signature': token
    });
    return this.http.get<AvailableSeats>(`${environment.api_map}/api/reserved/${XSignature}`, { headers });
  }

  /*
    * Actualizar el asiento para registrar que está vendido.
  */
  updateSeatSale(order_id: string, token: string, id: string): Observable<UpdateTickets> {
    const headers = new HttpHeaders({
      'X-Signature': token
    });
    return this.http.post<UpdateTickets>(`${environment.api_map}/api/order/${order_id}`, { 'order_id': id }, { headers });
  }

  /**
   * Obtener número de reservaciones que ya tenga el usuario
   * en el mismo evento y mismo lugar
   */
  deleteReservation(reservationId: string): Promise<void> {
    return this.firestore.collection(this.urlReservations).doc(reservationId)
      .delete();
  }


  /**
   * Obtener el modelo de datos de una reservación
   */
  getReservationModel() {
    return {
      total: 0,
      subtotal: 0,
      created_at: new Date(),
      deleted_at: null,
      entrance: null,
      event: null,
      id: '',
      physical_ticket_price: 0,
      location: null,
      openpay_transaction_id: '',
      section: null,
      selected_seats: [],
      updated_at: new Date(),
      user: null,
      type: '',
      date_position: 0, // cuando se confirme transacción de openpay, eliminar esta propiedad
      system: 'web',
      lang: 'es'
    };
  }

  /**
   * Actualizar el token de openpay en una reservación
   */
  saveOpenpayTokenTransaction(reservationId: string, transactionId: string, uid, currentUser, reservation, userIdPayment) {
    const batch = this.firestore.firestore.batch();
    batch.update(this.firestore.collection(this.url).doc(this.eventId).collection(this.urlReservations).doc(reservationId).ref, {
      openpay_transaction_id: transactionId,
      type: reservation.type
    });
    // guardar en subcolección de transacciones
    const transaction = {
      id: transactionId,
      amount: reservation.total,
      created_at: new Date(),
      deleted_at: null,
      openpay_transaction_id: transactionId,
      reservation: reservationId,
      event: {
        id: "",
        reservationId: "",
        order_id: this.XSignatureAndToken.xSignature,
        token: this.XSignatureAndToken.token,
        user_payment_id: userIdPayment
      },
      status: 'pending',
      updated_at: new Date(),
      user: {
        email: currentUser.email,
        first_name: currentUser.first_name,
        id: uid,
        image: currentUser.image,
        last_name: currentUser.last_name,
        openpay_id: currentUser.openpay_id,
        phone: currentUser.phone
      },
      status_3d_secure: 'pending' // "approved" "denied"
    };
    batch.set(this.firestore.collection(this.urlTransactions).doc(transaction.id).ref, transaction);
    return batch.commit();
  }

  listenOpenpayTransaction(transactionId: string): Observable<any> {
    const documentRef = this.firestore.collection(this.urlTransactions).doc(transactionId);
    return new Observable((observer) => {
      this.transactionSubscription = documentRef.valueChanges().subscribe(
        (data) => {
          observer.next(data);
        },
        (error) => {
          observer.error(error);
        }
      );
    });
  }

  stopListeningTransaction() {
    if (this.transactionSubscription) {
      this.transactionSubscription.unsubscribe();
    }
  }


  /**
   * Obtener las compras que ha realizado el usuario
   */
  /*getUserReservations(userId: string): Observable<any[]>{
    return this.firestore.collection(this.urlReservations, ref => ref.where('user.id', '==', userId).where('openpay_transaction_status', '==' , 'completed').orderBy('created_at', 'desc')).valueChanges();
  }*/
  getUserReservations(userId: string): Observable<any[]> {
    return this.firestore.collection(this.urlReservations, ref =>
      ref.where('user.id', '==', userId)
        .where('openpay_transaction_status', '==', 'completed')
        .orderBy('created_at', 'desc')
    ).valueChanges()
      .pipe(
        map((reservations: any[]) => {
          return reservations;
        })
      );
  }

  getUserReservationsOnce(userId: string): Observable<any> {
    const currentDate = new Date();
    return this.firestore.collection(this.urlReservations, ref => ref.where('user.id', '==', userId).where('openpay_transaction_status', '==', 'completed').orderBy('created_at', 'desc'))
      .get()
      .pipe(
        map((querySnapshot) => {
          // Filter documents locally based on the "endDate" property
          return querySnapshot.docs
            .map((doc) => doc.data())
            .filter((reservation) => {
              const r = reservation as any;
              if (r.event.startTime) {
                const dateEvent = dateStringToDate(r.event.date);
                return dateEvent > currentDate;
              }
            });
        })
      );
  }

  getPastUserReservationsOnce(userId: string): Observable<any> {
    const currentDate = new Date();
    return this.firestore.collection(this.urlReservations, ref => ref.where('user.id', '==', userId).where('openpay_transaction_status', '==', 'completed').orderBy('created_at', 'desc'))
      .get()
      .pipe(
        map((querySnapshot) => {
          // Filter documents locally based on the "endDate" property
          return querySnapshot.docs
            .map((doc) => doc.data())
            .filter((reservation) => {
              const r = reservation as any;
              if (r.event.startTime) {
                const dateEvent = dateStringToDate(r.event.date);
                return dateEvent > currentDate;
              }
            });
        })
      );
  }

  /**
   * En una reservación actualizar los datos correspondienes al seguro de boletos
   */
  updateSafeTicketData(reservation: any): Promise<void> {
    const reservationsCollections = this.firestore.collection(this.url).doc(this.eventId).collection(this.urlReservations).doc(reservation.id);
    return reservationsCollections.update({
      total: reservation.total,
      subtotal: reservation.subtotal,
      safe_tickets_total: reservation.safe_tickets_total,
      safe_tickets: reservation.safe_tickets,
      timer_started: reservation.timer_started,
      type: reservation.type,
      amount_safe_ticket: reservation.amount_safe_ticket,
      fee_service: reservation.fee_service,
      fee_openpay: reservation.fee_openpay,
      fee_amount_openpay: reservation.fee_amount_openpay
    });
  }



  /* REFACTOR SERVICE */

  getReservationById(reservationId: string): Observable<any> {
    return this.firestore.collection(this.urlReservations).doc(reservationId).get();
  }

  saveReservationLastPay(reservations: any) {
    const doc = this.firestore.collection(this.urlReservations).doc(reservations.id);
    return doc.set(reservations);
  }

  getCountries() {
    return this.firestore.collection(this.setCountries).valueChanges();
  }

  updateInformationUser(email: string, userData: any): Promise<void> {
    return this.firestore.collection('users', ref => ref.where('email', '==', email)).get().toPromise()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error('No user found with the provided email');
        }
        const docId = querySnapshot.docs[0].id;
        return this.firestore.collection('users').doc(docId).update(userData);
      });
  }

  async validEmailInUse(email: string) {
    const signInMethods = await this.afAuth.fetchSignInMethodsForEmail(email);
    return signInMethods.length > 0;
  }

  sendReservationMail(reservationId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_reservation_email`, { 'id': reservationId })
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.error(error);
            reject(error);
          }
        })
    });
  }

  createTicketPhysical(reservationId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/save_physical_tickets`, { 'id': reservationId })
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.error(error);
            reject(error);
          }
        })
    });
  }

  sendEmailTransferTicketsToDestination(transfer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_transfer_email_to_destination`, transfer)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

  sendEmailTransferTicketsToOrigin(transfer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_transfer_email_to_origin`, transfer)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

  sendEmailSuccessfulTransferToOrigin(transfer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_successful_transfer_email_to_origin`, transfer)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

  sendEmailSuccessfulTransferToDestination(transfer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_successful_transfer_email_to_destination`, transfer)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

  sendEmailRejectedTransferToOrigin(transfer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_rejected_transfer_email_to_origin`, transfer)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

  sendEmailRejectedTransferToDestination(transfer: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/send_rejected_transfer_email_to_destination`, transfer)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

  saveTicketsTransfer(transfer: any) {
    return this.firestore.collection('transfers_tickets')
      .doc(transfer.id)
      .set(transfer)
      .then(() => {
        return { result: true };
      })
      .catch((error) => {
        console.error('Error al guardar el evento:', error);
        return { result: false };
      });
  }

  updateTicketTransferOrigin(reservationId: string, valueTransfer: any): Promise<boolean> {
    return this.firestore.collection('reservations')
      .doc(reservationId)
      .update({
        transfer_progress: valueTransfer
      })
      .then(() => true)
      .catch(() => false);
  }

  cancelTransferByOriginReservationId(originReservationId): Promise<boolean> {
    return this.firestore.collection('transfers_tickets')
      .doc(originReservationId)
      .delete()
      .then(() => true)
      .catch(() => false);
  }

  sendContactMail(contactForm: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/save_ticket_contact`, contactForm)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.error(error);
            reject(error);
          }
        });
    });
  }
  sendSaleEventsWithUsMail(contactForm: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/saleEventsWithUs`, contactForm)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.error(error);
            reject(error);
          }
        });
    });
  }

  getTransferTicket(transferId) {
    return this.firestore.collection('transfers_tickets')
      .doc(transferId).get();
  }

  updateTransferTickets(transferId: string): Promise<any> {
    var documentData: any;
    return this.firestore.collection('transfers_tickets')
      .doc(transferId)
      .get()
      .toPromise()
      .then(doc => {
        if (doc.exists) {
          documentData = doc.data();
          return this.firestore.collection('transfers_tickets')
            .doc(transferId)
            .delete()
            .then(() => {
              return { success: true, data: documentData };
            });

        } else {
          return { success: false, data: 'Documento no encontrado' };
        }
      })
      .catch(error => {
        console.error('Error al obtener o eliminar el documento:', error);
        return { success: false, data: error.message };
      });
  }

  handleUpdateOrDeleteReservationsSeats(reservationId: string, SeatsTransfered: any): Promise<any> {
    return this.firestore.collection(this.urlReservations)
      .doc(reservationId)
      .get()
      .toPromise()
      .then(reserv => {
        if (reserv.exists) {

          var reservationData: any = reserv.data();
          if (reservationData.selected_seats.length > SeatsTransfered.length) {

            const idsSeats = SeatsTransfered.map(item => item.id);
            const newSeatsAccountOrigin = reservationData.selected_seats.filter(item => !idsSeats.includes(item.id));

            return this.firestore.collection(this.urlReservations)
              .doc(reservationId)
              .update({
                transfer_progress: {
                  transferId: "",
                  status: false
                },
                selected_seats: newSeatsAccountOrigin
              }).then(updateSeats => {
                return { success: true, data: reservationData };
              });
          } else {
            return this.firestore.collection(this.urlReservations)
              .doc(reservationId)
              .delete()
              .then(() => {
                console.log("Reservación eliminada correctamente");
                return { success: true, data: "documentData" };
              });
          }
        } else {
          return { success: false, data: 'Documento no encontrado' };
        }

      }).catch(error => {
        return { result: false, data: error.message };
      });
  }

  deleteReservationSeats(reservationId): Promise<any> {
    return this.firestore.collection(this.urlReservations)
      .doc(reservationId)
      .delete();
  }

  processPayment() {
    const api = "http://127.0.0.1:5001/boletera-app-c06d7/us-central1"
    const test = {
      id: "trild46kixqmlvjigwjr",
      reservation: {
        id: "trild46kixqmlvjigwjr"
      }
    }
    return this.http.post(`${api}/processPayment`, test);
  }

  GetVmtTiming(order_id: string, token: string): Observable<responseGetVmtTimingModel> {
    const headers = new HttpHeaders({
      'X-Signature': token
    });
    return this.http.get<responseGetVmtTimingModel>(`${environment.api_map}/api/reserved/${order_id}`, { headers });
  }

  generateFileAppleWallet(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(`${environment.firebaseUrl}/generateWalletTicket`, request)
        .subscribe({
          next: (data) => {
            console.log(data);
            resolve(data);
          },
          error: (error) => {
            console.log(error);
            reject(error);
          }
        })
    });
  }

}

